/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import ReactDOM from 'react-dom';
import Clarity from '@microsoft/clarity';

import App from './App';

Clarity.init('q42w3p7ge4');

const rootElm = document.getElementById('root')!;

if (rootElm.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElm
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElm
  );
}
