import { darken } from 'polished';
import styled from 'styled-components';

export const Banner = styled.div`
  background-position: center;
  background-size: cover;
  min-height: 100vh;

  p {
    color: #151517 !important;
    font-size: 24px;
    font-family: 'Archivo', sans-serif;
    font-weight: 300;
    line-height: 35px;
    span {
      font-weight: 600;
      color: #151517;
    }
  }

  .video {
    button {
      display: none !important;
    }
  }

  .btn-mute {
    z-index: 100;
    background: transparent;
    border-radius: 20px;
    left: 0px;
  }

  .mute {
    position: absolute;
    z-index: 100;
    height: 400px;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 8px;
    background: transparent;
    border-radius: 20px;
    border: 1px solid #fff;
    background: rgba(17, 107, 255, 0.8);
    backdrop-filter: blur(5px);
    filter: drop-shadow(0px 4px 40px rgba(17, 107, 255, 0.36));
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 43.5px;
  }

  video {
    border: 1px solid #303030;
    -webkit-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
  }

  h1 {
    color: #116bff;
    font-size: 43px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    line-height: 45px;
  }

  .container {
    max-width: 1820px;
  }

  .btn-chat {
    background: transparent;

    border: none;

    width: 60px;
    height: 60px;
  }
  .btn-save {
    width: 100%;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #ffffff;
    background: #116bff;
    border-radius: 20px;
    transition-duration: 0.3s;
    padding: 26px;

    :hover {
      opacity: 0.8;
    }
  }

  .logo {
    right: 2rem;
    top: 2rem;
  }

  .bg-right-side {
    background: #f4f4f4;
  }

  @media screen and (max-width: 1699px) {
    h1 {
      font-size: 36px;
    }
    .left-side {
      max-width: 1050px;
      margin: 0 auto;
    }
  }

  /* @media screen and (max-width: 1499px) {
    .left-side {
      max-width: 950px;
      margin: 0 auto;
    }
  } */

  @media screen and (max-width: 1399px) {
    h1 {
      font-size: 2.4vw;
    }

    p {
      font-size: 1.6vw;
    }
    .btn-save {
      font-size: 1.5vw;
      padding: 20px 26px;
    }
  }

  @media screen and (max-width: 1199px) {
    p {
      font-size: 1.7vw;
    }
  }

  @media screen and (max-width: 991px) {
    button {
      font-size: 20px;
    }

    h1 {
      font-size: 38px;
      text-align: center;
    }

    p {
      font-size: 22px;
      text-align: center;
    }

    .btn-save {
      font-size: 22px;
      padding: 20px 15px;
    }
  }

  @media screen and (min-width: 2160px) {
    .max-width {
      max-width: 1800px;
      margin: 0 auto;
    }
  }
`;

export const BannerThanks = styled.div`
  background: linear-gradient(6deg, #4d6af6 0%, #728ff7 100%);
  min-height: 100vh;

  h1 {
    color: #fff;
    font-family: 'Quicksand';
    font-size: 46px;
    font-weight: 700;
    line-height: 45px;
  }

  h2 {
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 22px;
    font-weight: 300;
    line-height: 35px;

    span {
      font-weight: 700;
    }
  }

  h3 {
    color: #ffe896;
    text-align: center;
    font-family: 'Archivo';
    font-size: 22px;
    font-weight: 400;
    line-height: 35px;
  }

  .bg-form {
    border-radius: 25px;
    background: #fffdfd;
    box-shadow: 0px 67px 70px 0px rgba(0, 0, 0, 0.12);
    padding: 43px 40px;
  }

  .bg-form h2 {
    color: #116bff;
    font-family: 'Archivo';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 45.5px;
    span {
      display: block;
      font-size: 28px;
      font-weight: 400;
    }
  }

  .bg-form p {
    color: #021027;
    text-align: center;
    font-family: 'Archivo';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
  }

  .bg-form button {
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 18px;
    background: #116bff;
    padding: 20.5px 0px;
    color: #fff;
    font-family: 'Archivo';
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }
  }

  .depoiment {
    img {
      width: 74px;
      height: 74px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 28px;
    }
    p {
      color: #fff;
      font-family: 'Quicksand';
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 0px;
      margin-top: 10px;
    }
    span {
      display: block;
      color: #fff;
      font-family: 'Source Sans Pro';
      font-size: 16px;
      font-weight: 300;
      line-height: 18px;
    }
  }

  .text-depoiment {
    p {
      color: #fff;
      font-family: 'Quicksand';
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
      span {
        font-size: 60px;

        font-family: 'Source Sans Pro';
      }
    }
    span.text {
      display: block;
      color: #fff;
      font-family: 'Source Sans Pro';
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      padding-left: 32px;
    }
  }

  .email-folder {
    p {
      color: #585656;
      font-family: 'Archivo';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      text-align: start;
    }

    .email {
      font-weight: 500;
    }

    .check-folder {
      color: #116bff;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 575px) {
    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 20px;
    }

    .bg-form {
      padding: 40px 20px;
    }

    .bg-form h2 {
      font-size: 34px;
    }

    .bg-form h2 span {
      font-size: 24px;
    }

    .bg-form p {
      font-size: 20px;
    }

    .depoiment {
      img {
        width: 72px;
        height: 72px;
      }
      p {
        font-size: 10px;
        line-height: 8px;
      }

      span {
        font-size: 8px;
      }
    }

    .text-depoiment {
      p {
        font-size: 16px;
        line-height: 18px;
        span {
          font-size: 16px;
        }
      }
      span.text {
        font-size: 13px;
        font-weight: 300;
        line-height: 15px;
        padding-left: 0px;
      }
    }
    .email-folder {
      p {
        color: #585656;
        font-family: 'Archivo';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        text-align: center;
      }

      .email {
        font-weight: 500;
      }

      .check-folder {
        color: #116bff;
        font-weight: 600;
      }
    }
  }
`;
