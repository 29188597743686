import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { differenceInSeconds, parseISO, subMinutes } from 'date-fns';
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import { Banner, BannerThanks } from './styles';

import LiveChat from '~/components/LiveChat';
import Player from '~/components/Player';

import thumbModal from '~/assets/defaults/thumb-video-modal.png';
import chatIcon from '~/assets/icons/chat-icon.svg';
import { useResize } from '~/hooks/Resize';
import jeremy from '~/assets/defaults/jeremy.png';
import thumbFree from '~/assets/defaults/thumb-free.png';
import waves from '~/assets/animations/waves-sound.json';
import check from '~/assets/animations/check.json';
import muteImg from '~/assets/icons/mute.svg';
import api from '~/services/api';

const WebinarLive: React.FC = () => {
  const { width } = useResize();
  const history = useHistory();
  const [activeChat, setActiveChat] = useState(true);
  const [pageThanks, setPageThanks] = useState(false);
  const [initWebinar, setInitWebinar] = useState(false);
  const [mutedVideo, setMutedVideo] = useState(true);
  const [mutedVideoWebinar, setMutedVideoWebinar] = useState(true);
  const [videoTime, setVideoTime] = useState(0);

  const initialTime = useMemo(() => {
    const time = localStorage.getItem('@AutoAffiliate:initialTime');
    if (time) {
      return parseISO(time);
    }

    return new Date();
  }, []);

  const [remainingTime, setRemainingTime] = useState(
    differenceInSeconds(initialTime, new Date())
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newRemainingTime = differenceInSeconds(initialTime, new Date());
      setRemainingTime(newRemainingTime);

      if (newRemainingTime <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [initialTime]);

  const minutes = useMemo(
    () => Math.floor(remainingTime / 60),
    [remainingTime]
  );

  const seconds = useMemo(() => remainingTime % 60, [remainingTime]);

  useEffect(() => {
    setInitWebinar(minutes <= 0 && seconds <= 0);
    if (minutes === 0 && seconds === 0) {
      setMutedVideoWebinar(true);
      setMutedVideo(false);
    }
  }, [minutes, seconds]);

  const userData = useMemo(() => {
    const data = localStorage.getItem('@AutoAffiliate:userData');
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  }, []);

  // codigo abaixo para esconder intercom

  // const intercomInterval = setInterval(() => {
  // const intercom = document.getElementsByClassName('intercom-namespace');
  //   for (let i = 0; i < intercom.length; i += 1) {
  //     intercom[i].classList.add('d-none');
  //   }
  // }, 10);

  // setTimeout(() => {
  //   clearInterval(intercomInterval);
  // }, 30000);

  const handleActiveChat = useCallback(() => {
    setActiveChat(!activeChat);
  }, [activeChat]);

  useEffect(() => {
    if (width < 992) {
      setActiveChat(true);
    }
  }, [width]);

  const handleLoad = useCallback(
    (e) => {
      if (initialTime) {
        const secondsDiff = differenceInSeconds(new Date(), initialTime);
        e.target.volume = 0.3;
        if (secondsDiff > 0) {
          if (secondsDiff < e.target.duration) {
            e.target.currentTime = secondsDiff;
            e.target.play();
          } else {
            e.target.currentTime = e.target.duration;
            e.target.pause();
          }
        }
      }
    },
    [initialTime]
  );

  const handleEnded = useCallback((e) => {
    const vid = e.target;
    if (vid) {
      vid.currentTime = vid.duration;
      vid.pause();
      setPageThanks(true);
    }
  }, []);

  const handleVideoEnded = useCallback(() => {
    setInitWebinar(true);
    setMutedVideoWebinar(true);
    setMutedVideo(false);
  }, []);

  const handleVideoLoaded = useCallback(
    (e) => {
      if (initialTime) {
        const secondsDiff = differenceInSeconds(
          new Date(),
          subMinutes(initialTime, 7)
        );
        e.target.volume = 0.1;
        if (secondsDiff > 0) {
          if (secondsDiff < e.target.duration) {
            e.target.currentTime = secondsDiff + 1;
          } else {
            e.target.pause();
          }
        }
      }
    },
    [initialTime]
  );

  const handleClickDemute = useCallback(() => {
    if (new Date() > initialTime) {
      setMutedVideo((state) => !state);
    } else {
      setMutedVideoWebinar((state) => !state);
    }
  }, [initialTime]);

  const handleTimeUpdate = useCallback((e) => {
    setVideoTime(e.target.currentTime);
  }, []);

  const handleClick = useCallback(async () => {
    if (userData) {
      const response = await api.post('users/sessions/set-data', {
        user_id: userData.id,
        data: 'auto-login',
      });

      localStorage.setItem(
        '@AutoAffiliate:auto-login',
        `${process.env.REACT_APP_APP_URL}/check-login/${response.data.token}`
      );

      history.push(`${process.env.PUBLIC_URL}/slo-1`, {
        from: history.location,
      });

      // window.location.href = `${process.env.REACT_APP_APP_URL}/check-login/${response.data.token}`;
    }
  }, [history, userData]);

  return (
    <>
      <Helmet>
        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />
      </Helmet>
      {!pageThanks ? (
        <Banner>
          <div className="container position-relative vh-lg-100 container-zindex">
            <div className={`${width < 992 && 'd-none'} row`}>
              <div className="col-12 d-flex justify-content-end position-absolute mt-3 px-3">
                <button
                  type="button"
                  className={`${activeChat && 'd-none'} btn-chat`}
                  onClick={handleActiveChat}
                  title="Coming Soon"
                >
                  <img src={chatIcon} alt="Chat" />{' '}
                </button>
              </div>
            </div>

            <div
              className={`row h-lg-100 ${
                !activeChat && 'justify-content-center'
              }`}
            >
              <div
                className={`col-lg-8 col-xxl-9 px-sm-4 ${
                  activeChat ? 'px-xl-5' : 'px-xl-0'
                } left-side`}
              >
                <div className="row mb-2 mb-xxl-4">
                  <div className="col-12">
                    <h1
                      className={`${
                        !activeChat && 'text-center'
                      } mb-lg-0 mb-xxl-2 pt-3 pt-xxl-4`}
                    >
                      DISCOVER EXACTLY HOW AUTOAFFILIATE WORKS
                    </h1>

                    <p
                      className={`${
                        !activeChat && 'text-center'
                      } mb-2 my-xxl-2`}
                    >
                      And How You Can Start Leveraging It Today To{' '}
                      <span>Build Multiple Streams Of Income</span>
                    </p>
                  </div>
                </div>

                <div className="row justify-content-center position-relative">
                  <div className="col-12 position-relative cursor-none pe-none video">
                    <Player
                      id="video_webinar"
                      src="https://cdn.autoaffiliate.ai/videos/AutoAffiliate+Countdown.mp4"
                      thumbnail={thumbFree}
                      autoPlay
                      onEnded={handleVideoEnded}
                      className={initWebinar ? 'd-none' : 'd-block'}
                      muted={mutedVideoWebinar}
                      onLoad={handleVideoLoaded}
                    />
                    <Player
                      id="video"
                      src="https://cdn.autoaffiliate.ai/videos/New+AutoWebinr+compliant+2-5-24.mp4"
                      thumbnail={thumbModal}
                      onEnded={handleEnded}
                      className={!initWebinar ? 'd-none' : 'd-block'}
                      autoPlay={initWebinar}
                      onLoad={handleLoad}
                      muted={mutedVideo}
                      onTimeUpdate={handleTimeUpdate}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleClickDemute}
                    className={`btn-mute w-100 h-100 border-none position-absolute ${
                      mutedVideoWebinar && mutedVideo ? '' : 'opacity-0'
                    }`}
                  >
                    <span className="mute pt-3 pt-md-5">
                      <Lottie
                        options={{
                          animationData: waves,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={width > 480 ? 100 : 50}
                      />
                      <span className="d-block mt-3 mt-md-5 mb-3">
                        Your Video Is Playing Click Here To Unmute
                      </span>{' '}
                      <img src={muteImg} alt="mute" />
                    </span>
                  </button>
                </div>
              </div>
              <div
                className={`col-lg-4 col-xxl-3 px-0 ps-lg-3 pe-lg-0 ${
                  activeChat ? 'd-block' : 'd-none'
                }`}
              >
                <div className="h-lg-100 bg-right-side">
                  <LiveChat
                    chatId="webinar-live-chat-test"
                    active={activeChat}
                    onClick={handleActiveChat}
                    userData={userData}
                    videoTime={videoTime}
                    initWebinar={initWebinar}
                  />
                </div>
              </div>
            </div>
          </div>
        </Banner>
      ) : (
        <BannerThanks>
          <div className="container">
            <div className="row">
              <div className="col text-center mt-5">
                <h1>
                  <b>Thank You For Attending The Webinar!</b>
                </h1>
                <h2>
                  And learning about how AutoAffiliate can be the{' '}
                  <span>key to your financial freedom.</span>
                </h2>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-8 mt-4 mt-sm-5 mb-sm-3">
                <div className="bg-form">
                  <h2 className="mb-0 mb-sm-2">
                    <span>AS A GIFT FOR ATTENDING&nbsp;🎁</span>
                    WE HAVE A BONUS FOR&nbsp;YOU!
                  </h2>
                  <p className="px-sm-3 my-4 my-sm-5">
                    We've already set up an account for you within our system,
                    granting you instant access to our vibrant community and
                    powerful tools.
                  </p>
                  <div className="px-2 px-sm-3 mb-3">
                    <button
                      type="button"
                      className="border-0"
                      onClick={handleClick}
                    >
                      {`ACCESS ${width > 575 ? 'YOUR ' : ' '}ACCOUNT HERE`}
                    </button>
                  </div>
                  <div className="d-lg-flex align-items-center email-folder px-2 px-sm-3 px-lg-5 mb-sm-3">
                    <div>
                      <Lottie
                        options={{
                          animationData: check,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={50}
                        width={50}
                      />
                    </div>
                    <p className="mb-0 ps-lg-4">
                      An email has been sent to{' '}
                      <span className="email">{userData.email}</span> with your
                      account credentials. If you have not received the email
                      after a few minutes,{' '}
                      <span className="check-folder">
                        please check your spam folder.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8 my-5">
                <div className="d-flex">
                  <div className="text-center depoiment">
                    <img src={jeremy} alt="Avatar" />
                    <p>Jeremy&nbsp;Engel</p>
                    <span>Member since 2023</span>
                  </div>
                  <div className="text-depoiment mt-sm-2 ps-4">
                    <p className="d-flex">
                      <span>“</span>AutoAffiliate is more than just a platform.”
                    </p>
                    <span className="text">
                      “It's a community of like-minded individuals striving for
                      financial freedom. Their robust tools and unwavering
                      support have helped me a lot with my affiliate marketing
                      journey.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BannerThanks>
      )}
    </>
  );
};

export default WebinarLive;
